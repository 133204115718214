<template>
  <div>
    <div class="align-center text-center mt-6 mx-6">
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{
            $t("tables.actionsImplemented")
          }}</v-toolbar-title>
        </v-toolbar>

        <!-- Table in English without notification Service-->
        <div
          v-if="$i18n.locale == 'en' && !userServices.notification"
          class="pl-4 pr-4 pt-2 pb-2"
        >
          <v-data-table
            :headers="headers"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->

            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-select
                            class=""
                            v-model="editedItem.eventType"
                            :items="actions"
                            :search-input.sync="chosenAction"
                            :label="$t('global.action')"
                            persistent-hint
                            outlined
                          >
                          </v-select>
                        </v-col>
                        <v-col v-if="editedItem.message === 'Disconnect' || editedItem.message === 'Reconnect'" cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Message"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.message !== 'Disconnect' && editedItem.message !== 'Reconnect'" cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Message"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <div class="red--text" v-html="error" />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
            </template>

            <!--END OF TABLE TEMPLATE - CRUD METHODS-->
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <!-- Table in English with notification Service-->
        <div
          v-if="$i18n.locale == 'en' && userServices.notification"
          class="pl-4 pr-4 pt-2 pb-2"
        >
          <v-data-table
            :headers="headersWithNotification"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->

            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="700px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                          <v-select
                            class=""
                            v-model="editedItem.eventType"
                            :items="actions"
                            :search-input.sync="chosenAction"
                            :label="$t('global.action')"
                            persistent-hint
                            outlined
                          >
                          </v-select>
                        </v-col>
                        <v-col v-if="editedItem.message === 'Disconnect' || editedItem.message === 'Reconnect'" cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Message"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.message !== 'Disconnect' && editedItem.message !== 'Reconnect'" cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Message"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-select
                            v-if="userServices.notification"
                            class=""
                            v-model="editedItem.sendEmail"
                            :items="sendEmail"
                            :search-input.sync="sendEmailSelection"
                            :label="$t('global.sendEmail')"
                            persistent-hint
                            outlined
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <div class="red--text" v-html="error" />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
            </template>

            <!--END OF TABLE TEMPLATE - CRUD METHODS-->
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <!-- Table in Portuguese without Notification service-->
        <div
          v-if="$i18n.locale == 'pt' && !userServices.notification"
          class="pl-4 pr-4 pt-2 pb-2"
        >
          <v-data-table
            :headers="headersPt"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-select
                            class=""
                            v-model="editedItem.eventType"
                            :items="actions"
                            :search-input.sync="chosenAction"
                            :label="$t('global.action')"
                            persistent-hint
                            outlined
                          >
                          </v-select>
                        </v-col>
                        <v-col v-if="editedItem.message === 'Disconnect' || editedItem.message === 'Reconnect'" cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Mensagem"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.message !== 'Disconnect' && editedItem.message !== 'Reconnect'" cols="12" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Mensagem"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <div class="red--text" v-html="error" />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                editar
              </v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <!-- Table in Portuguese with Notification service-->
        <div
          v-if="$i18n.locale == 'pt' && userServices.notification"
          class="pl-4 pr-4 pt-2 pb-2"
        >
          <v-data-table
            :headers="headersWithNotificationPt"
            :search="search"
            :items="entries"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="700px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                          <v-select
                            class=""
                            v-model="editedItem.eventType"
                            :items="actionsPt"
                            :search-input.sync="chosenAction"
                            :label="$t('global.action')"
                            persistent-hint
                            outlined
                          >
                          </v-select>
                        </v-col>
                        <v-col v-if="editedItem.message === 'Disconnect' || editedItem.message === 'Reconnect'" cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Mensagem"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.message !== 'Disconnect' && editedItem.message !== 'Reconnect'" cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.message"
                            label="Mensagem"
                            :hint="$t('global.maximum30char')"
                            persistent-hint
                            autocomplete="off"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-select
                            v-if="userServices.notification"
                            class=""
                            v-model="editedItem.sendEmail"
                            :items="sendEmailPt"
                            :search-input.sync="sendEmailSelection"
                            :label="$t('global.sendEmail')"
                            persistent-hint
                            outlined
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <div class="red--text" v-html="error" />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                editar
              </v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import AppService from "@/services/AppService";
import HelperMethods from "@/utilities/HelperMethods";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userInfo: {},
      userServices: {},
      entries: [],
      entryIds: [],
      devices: [],
      actions: ["Critical Alert", "Warning"],
      actionsPt: ["Alerta Critico", "Alerta"],
      headers: [
        { text: "Description", value: "description" },
        { text: "Action", value: "eventType" },
        { text: "Message", value: "message" },
        { text: "Date Created", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersPt: [
        { text: "Descrição", value: "description" },
        { text: "Acção", value: "eventType" },
        { text: "Mensagem", value: "message" },
        { text: "Data de Criação", value: "createdAt" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      headersWithNotification: [
        { text: "Description", value: "description" },
        { text: "Action", value: "eventType" },
        { text: "Message", value: "message" },
        { text: "Notification", value: "sendEmail" },
        { text: "Date Created", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersWithNotificationPt: [
        { text: "Descrição", value: "description" },
        { text: "Acção", value: "eventType" },
        { text: "Mensagem", value: "message" },
        { text: "Notificação", value: "sendEmail" },
        { text: "Data de Criação", value: "createdAt" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      typesOfEvent: ["Critical Alert", "Warning"],
      typesOfEventPt: ["Alerta Critico", "Alerta"],
      typesOfData: [
        "Temperature",
        "Carbon Dioxide",
        "Noise",
        "Humidity",
        "Light Intensity",
        "Air Quality",
        "Pressure",
        "Wifi Signal",
        "Light Temperature",
        "Devices Count",
        "People Flow",
        "Disconnected",
        "Reconnected",
      ],
      typesOfDataPt: [
        "Temperatura",
        "Dióxido de Carbono",
        "Ruído",
        "Humidade",
        "Intensidade da Luz",
        "Qualidade do Ar",
        "Pressão",
        "Sinal Wifi",
        "Temperatura da Luz",
        "Número de Dispositivos",
        "Fluxo de Pessoas",
        "Desconectou",
        "Reconectou",
      ],
      sendEmail: ["No", "Yes"],
      sendEmailPt: ["Nao", "Sim"],
      sendEmailSelection: "",
      action: "",
      message: "",
      chosenId: "",
      chosenAction: "",
      entryId: null,
      search: null,
      groupId: null,
      error: null,
      success: null,

      //v-data-table template variables
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        action: "",
        message: "",
        notification: null,
      },
      defaultItem: {
        action: "",
        message: "",
        notification: null,
      },
    };
  },
  computed: {
    ...mapState(["userRole"]),
    formTitle() {
      if (this.$i18n.locale == "pt") {
        return this.editedIndex === -1 ? "Nova Entrada" : "Editar Entrada";
      } else return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  methods: {
    async retrieveEntries() {
      let self = this;

      try {
        const response = await EventService.getEntries(this.groupId);
        this.entries = response.data;
      } catch (err) {
        console.log(err);
        return;
      }

      this.entries.forEach(function (value) {
        self.entryIds.push(value["id"]);
      });

      function buildDescriptionString(deviceName, dataType, condition, value) {
        if (value === 0) {
          return deviceName + ": " + dataType + " " + condition;
        } else
          return deviceName + ": " + dataType + " " + condition + " " + value;
      }

      this.entries.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.deviceName = HelperMethods.nameDevice(
          value["refDevice"],
          self.devices
        );
        value.dataType = HelperMethods.nameDataType(
          value["dataType"],
          self.$i18n.locale,
          self.typesOfData,
          self.typesOfDataPt
        );
        value.condition = HelperMethods.convertCondition(value["condition"]);
        value.eventType = HelperMethods.nameEventType(
          value["eventType"],
          self.$i18n.locale,
          self.typesOfEvent,
          self.typesOfEventPt
        );
        value.sendEmail = HelperMethods.nameNotificationType(
          value["sendEmail"],
          self.$i18n.locale,
          self.sendEmail,
          self.sendEmailPt
        );
        value.description = buildDescriptionString(
          value["deviceName"],
          value["dataType"],
          value["condition"],
          value["value"]
        );
      });
    },

    refreshList() {
      this.retrieveEntries();
    },

    editItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.updateAction(this.editedItem);
    },

    async updateAction(editedItem) {
      let self = this;
      if (editedItem.message.length >= 31) {
        if (this.$i18n.locale == "pt") {
          this.error =
            "Por favor use um máximo de 30 caracteres para a mensagem.";
          return;
        } else
          return (this.error =
            "Please use a maximum of 30 characters for message input.");
      }

      if (editedItem.eventType === "Critical Alert") {
        this.actionChosen = 0;
      } else if (editedItem.eventType === "Alerta Critico") {
        this.actionChosen = 0;
      } else this.actionChosen = 1;

      if (editedItem.sendEmail === "No") {
        this.sendEmailSelection = 0;
      } else if (editedItem.sendEmail === "Nao") {
        this.sendEmailSelection = 0;
      } else this.sendEmailSelection = 1;

      try {
        const response = await EventService.updateAction({
          id: self.editedItem.id,
          eventType: Number(this.actionChosen),
          message: self.editedItem.message,
          notification: Number(this.sendEmailSelection),
        });
        this.error = null;
        this.success = "Action updated.";
        this.refreshList();
        console.log("Response: ", response);

        await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `add/edit action conditionId: ${response.data.entry.id}`,
        });
      } catch (err) {
        console.log("UpdateAction error: ", err);
        this.error = err.response.data.error;
      }
      this.close();
    },
  },

  async mounted() {
    // Loading user info
    this.userServices = this.$store.getters.userServices;
    this.userInfo = this.$store.getters.userInfo;
    this.groupId = this.$store.getters.groupId;
    this.devices = this.$store.getters.devices;

    this.retrieveEntries();
  },
  watch: {
    entryId(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.entryId.pop());
      }
    },
    action(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.action.pop());
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style scoped></style>
